import React, {useCallback, useContext} from "react";
import {styled, useTheme} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {CardActionArea, CardContent} from '@mui/material';
import PageContainer from "../components/ui/PageContainer";
import PageContext from "../store/page-context";
import {makeStyles} from '@mui/styles';
import axios from "axios";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ReactGA from "react-ga4";

var moment = require('moment');
moment.defaultFormat = "dddd, MMMM Do YYYY";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function BlogCard(props) {
    return (
        <Card sx={{maxWidth: 345}}>
            <CardActionArea href={props.url} target="_blank" component="a">
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.desc}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default function Blog(props) {
    const pageCtx = useContext(PageContext);
    pageCtx.updateTitle("Blog");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});


    const classes = useStyles();

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    };

    const handleChangeIndex = (index) => {
        setValue(index);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
    };

    const [linkdata, setLinkData] = React.useState([]);
    const [topicdata, setTopicData] = React.useState(null);
    const [errormsg, setErrorMsg] = React.useState(null);


    const loadLinkData = useCallback(() => {
        axios.get('/blogs.json', {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            let topics = [];
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].show === true) {
                    topics.push(response.data[i]);
                }
            }
            topics.sort(function (a, b) {
                if (a.ordinal < b.ordinal) return -1;
                if (a.ordinal > b.ordinal) return 1;
                return 0;
            });
            setLinkData(topics);
            // isLoading(false);
        }).catch((error) => {
            // setError(error.toString());
        });

    }, []);

    React.useEffect(() => {
        loadLinkData();
    }, [loadLinkData, value]);


    const loadTopic = useCallback(() => {
        setErrorMsg(null);
        setTopicData(null);

        if (linkdata === null || linkdata.length === 0) return;

        const topic = linkdata[value];
        if (topic === null || topic.articles === null) return;

        const url = `/blogs/${topic.topic}/articles.json`;

        axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            let articles = response.data;
            articles.sort(function (a, b) {
                if (a.date < b.date) return 1;
                if (a.date > b.date) return -1;
                return 0;
            });

            setTopicData(articles);
            // isLoading(false);
        }).catch((error) => {
            if (error.response && error.response.status !== 404) {
                setErrorMsg(error.toString());
            }
        });

    }, [linkdata, value]);

    React.useEffect(() => {
        loadTopic();
    }, [loadTopic]);


    const display_date = (epoch) => {
        let d = moment.unix(epoch).format();
        return d;
    }

    return <PageContainer>
        <Box sx={{bgcolor: 'background.paper'}}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs"
                >
                    {linkdata.map((group, idx) => {
                        return <Tab label={group.title} {...a11yProps(idx)} />
                    })}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {linkdata.map((group, idx) => {
                    return <TabPanel value={value} index={idx} dir={theme.direction}>
                        <Typography variant="h6">
                            {group.description}
                        </Typography>

                        <Box height={4}/>

                        <Typography variant="h6" bgcolor={"red"}>
                            <Box height={4}/>
                        </Typography>

                        <Box height={20}/>

                        {errormsg !== null && <Typography variant="h4">
                            {errormsg}
                        </Typography>}

                        {topicdata === null &&
                            <Typography variant="h5">no articles yet</Typography>
                        }

                        {topicdata !== null &&
                            <React.Fragment>
                                {topicdata.map((article, idx) => {
                                        return <React.Fragment>
                                            <Typography variant="h7">
                                                {display_date(article.date)}
                                            </Typography>
                                            <Typography variant="h5">
                                                {article.title}
                                            </Typography>
                                            <Box height={20}/>
                                        </React.Fragment>
                                    }
                                )}
                            </React.Fragment>
                        }
                    </TabPanel>
                })}
            </SwipeableViews>
        </Box>
    </PageContainer>
};