import React, {useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import {CardContent, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useLocalStorage} from "../../libs/useLocalStorage";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";

var humanizeDuration = require('humanize-duration');

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box border={0}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));

export default function VehicleLoan(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();

    const [vehiclename, setVehicleName] = useLocalStorage("vehicleName", "No Name");
    const [vehicleprice, setVehiclePrice] = useLocalStorage("vehicleprice", 10000);
    const [vehiclenew, setVehicleNew] = useLocalStorage("vehiclenew", true);
    const [monthlyfuel, setMonthlyFuel] = useLocalStorage("monthlyfuel", 500);
    const [monthlyoil, setMonthlyOil] = useLocalStorage("monthlyoil", 2.2);
    const [loanrate, setLoanRate] = useLocalStorage("loanrate", 6.0);
    const [downpayment, setDownpayment] = useLocalStorage("downpayment", 1000);
    const [rebate, setRebate] = useLocalStorage("rebate", 1000);

    const [loanduration, setLoanDuration] = useLocalStorage("loanduration", 96);
    const onLoanDurationChanged = (e) => setLoanDuration(parseInt(e.target.value));

    const calcFutureValue = () => {
        var newprice = vehicleprice;
        if (vehiclenew) newprice = newprice - (newprice * 0.1);
        for (let i = 0; i < 4; i++) {
            newprice = newprice - (newprice * 0.1);
        }
        return newprice;
    }

    const calcFutureEnergySpent = () => {
        var spent = (monthlyfuel + monthlyoil) * 48;
        return spent;
    }

    const calcFutureLoan = () => {
        let principal = vehicleprice - downpayment - rebate;
        let rate = loanrate / 100 / 12;
        for (let i = 0; i < loanduration; i++) {
            let interest = (principal * rate);
        }
        return 0.0;
    }

    const calcLoanPrincipal = () => {
        // M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]
        let principal = vehicleprice - downpayment - rebate;
        return principal.toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    const calcLoadPayment = () => {
        // M = P [ i(1 + i)^n ] / [ (1 + i)^n – 1]
        let P = vehicleprice - downpayment - rebate;
        let I = loanrate / 100 / 12;
        let N = loanduration;
        let E = Math.pow(1 + I, N);
        let M = P * ((I * E) / (E - 1));

        // console.log("Principle " + P.toString());
        // console.log("Interest " + I.toString());
        // console.log("Months " + N.toString());
        // console.log("Payment " + M.toString());

        return M.toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    const calcInterest = (months) => {
        let P = vehicleprice - downpayment - rebate;
        let I = loanrate / 100 / 12;
        let N = loanduration;
        let E = Math.pow(1 + I, N);
        let M = P * (I * E / (E - 1));
        let monthly = ((M * loanduration) - P) / loanduration; // TODO: incorrect interest per month
        //return monthly * months;
        return 0.0;
    }

    const calcMonthlyCost = () => {
        let sellprice = calcFutureValue();
        let purchaseprice = vehicleprice - rebate; // TODO: add interest paid up to 4 months
        let energy = (monthlyfuel + monthlyoil) * 48;
        let interest = calcInterest(48);
        let monthly = (purchaseprice + energy - sellprice + interest) / 48;
        return monthly.toLocaleString(undefined, {maximumFractionDigits: 2});
    }


    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">
                                Loan Details
                            </Typography>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="loanduration" aria-describedby="my-helper-text"
                                           defaultValue={loanduration}
                                           required
                                           fullWidth
                                           onChange={onLoanDurationChanged}
                                    />
                                    <FormHelperText id="my-helper-text">Loan Duration in Months</FormHelperText>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid container xs="12" spacing="1" height={30}>
                    </Grid>

                </Grid>
                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="8.25" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h5">
                                Loan Summary
                            </Typography>

                            <Typography variant="body">
                                <p>
                                    Based on historical industry depreciation, after 48 months this vehicle will
                                    have depreciated to a value
                                    of ${calcFutureValue().toLocaleString(undefined, {maximumFractionDigits: 2})}
                                </p>
                                <p>
                                    A loan of ${calcLoanPrincipal()} over {loanduration} months at
                                    a {loanrate}% interest rate will require monthly payments
                                    of ${calcLoadPayment()}.
                                </p>

                                <p>
                                    The cost of driving this vehicle will be an
                                    estimated ${calcMonthlyCost()} if sold at 48 months after purchase.
                                    {/*The cost includes interest payments of*/}
                                    {/*${calcInterest(48).toLocaleString(undefined, {maximumFractionDigits: 2})} up to*/}
                                    {/*the sell date.*/}
                                </p>
                                {/*<p>*/}
                                {/*    <b>WARNING: not yet calculating interest</b>*/}
                                {/*</p>*/}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>;
};