import React, {Fragment, useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import Input from '@mui/material/Input';
import {CardContent, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import humanizeDuration from "humanize-duration";
import {useLocalStorage} from "../../libs/useLocalStorage";


var moment = require('moment');


// https://www.jcalc.net/battery-size-calculator

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));

export default function CalcChargeTime(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();

    const [cellcount, setCellCountValue] = useLocalStorage("cellseries", 8);
    const onCellCountChange = (e) => setCellCountValue(parseInt(e.target.value));

    const [parallel, setParallelValue] = useLocalStorage("parallel", 1);
    const onParallelChange = (e) => setParallelValue(parseInt(e.target.value));

    const [cellvolts, setCellVoltValue] = useLocalStorage("cellvolts", 3.2);
    const onCellVoltChange = (e) => setCellVoltValue(parseFloat(e.target.value));

    const [cellah, setCellAhValue] = useLocalStorage("cellah", 230);
    const onCellAhChange = (e) => setCellAhValue(parseFloat(e.target.value));


    const [highestcharge, setHighestChargeValue] = useLocalStorage("highestcharge", 90);
    const onHighestChargeChange = (e) => setHighestChargeValue(Math.max(lowestcharge, parseInt(e.target.value)));

    const [lowestcharge, setLowestChargeValue] = useLocalStorage("lowestcharge", 20);
    const onLowestChargeChange = (e) => setLowestChargeValue(Math.min(highestcharge, parseInt(e.target.value)));

    const is_consumption_ready = () => {
        return lowestcharge >= 0 && highestcharge > lowestcharge;
    }

    const capacity_usable = () => {
        let capacity = cellah * parallel * cellcount * cellvolts;
        let lo = Math.min(100, Math.max(0, lowestcharge));
        let hi = Math.max(0, Math.min(100, highestcharge));

        let low_cut = capacity * (lo / 100);
        let top_cut = capacity * ((100 - hi) / 100);
        capacity = capacity - low_cut - top_cut;
        return (capacity / 1000).toFixed(2);
    }

    const batteryvolts = () => {
        // let n = (cellcount * cellvolts).toFixed(0);
        // if (n < 12) return (cellcount * cellvolts).toFixed(1);
        // if (n < 24) return 12;
        // if (n < 48) return 24;
        // if (n < 55) return 48;
        return (cellcount * cellvolts).toFixed(1);
    }

    const capacity = () => {
        let volts = cellcount * cellvolts;
        return ((cellah * volts) / 1000).toFixed(2);
    }

    const maxcharge = () => {
        return (cellah * 0.1).toFixed(0);
    }

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">
                                Specifications
                            </Typography>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="cellcount" aria-describedby="my-helper-text"
                                           defaultValue={cellcount}
                                           required
                                           fullWidth
                                           onChange={onCellCountChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Cells in Series</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="parallel" aria-describedby="my-helper-text"
                                           defaultValue={parallel}
                                           required
                                           fullWidth
                                           InputProps={{inputProps: {min: 1, max: 100}}}
                                           onChange={onParallelChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Batteries in Parallel</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="cellvolt" aria-describedby="my-helper-text"
                                           defaultValue={cellvolts}
                                           required
                                           fullWidth
                                           onChange={onCellVoltChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Target (V) per cell</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="cellah" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           onChange={onCellAhChange}
                                           defaultValue={cellah}
                                    />
                                    <FormHelperText id="my-helper-text">Amp Hours (Ah) per cell</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputlow" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           InputProps={{
                                               inputProps: {min: 0, max: 90},
                                               inputMode: 'numeric',
                                               pattern: '[0-9]*'
                                           }}
                                           onChange={onLowestChargeChange}
                                           defaultValue={lowestcharge}
                                    />
                                    <FormHelperText id="my-helper-text">Lowest Charge %</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputhigh" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           InputProps={{
                                               inputProps: {min: 10, max: 100},
                                               inputMode: 'numeric',
                                               pattern: '[0-9]*'
                                           }}
                                           onChange={onHighestChargeChange}
                                           defaultValue={highestcharge}
                                    />
                                    <FormHelperText id="my-helper-text">Highest Charge %</FormHelperText>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="6" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h5">
                                Battery Sizing
                            </Typography>

                            {cellcount > 0 ? <Fragment>

                                    <Typography variant="body">{is_consumption_ready() ?
                                        <p>
                                            This is a {batteryvolts()}v battery with {capacity()} kWh capacity,
                                            and {capacity_usable()} kWh of usable capacity.
                                        </p> :
                                        <p>
                                            This is a {batteryvolts()}v battery with {capacity()} kWh capacity.
                                        </p>
                                    }
                                    </Typography>


                                </Fragment> :
                                <Typography variant="body">
                                    <p>
                                        Setup at least one cell with its volts and amp hours to get started
                                    </p>
                                </Typography>}

                            <br/>
                            <br/>
                            <img src="/series-parallel.jpg"/>

                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>;
};