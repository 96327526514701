import React, {useState, useCallback, useEffect} from 'react';
import moment from "moment";

let logoutTimer;

const AuthContext = React.createContext({
    googleToken: '',
    myToken: '',
    userName: '',
    expirationTime: '',
    isLoggedIn: false,
    login: (googleToken, expirationTime, myToken, userName) => {
    },
    logout: () => {
    },
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = moment.unix(expirationTime);

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

const retrieveStoredData = () => {
    const storedGoogleToken = typeof window !== "undefined" ? window.localStorage.getItem('googleToken') : "";
    const storedMyToken = typeof window !== "undefined" ? window.localStorage.getItem('myToken') : "";
    const storedUserName = typeof window !== "undefined" ? window.localStorage.getItem('userName') : "";
    const storedExpirationTime = typeof window !== "undefined" ? window.localStorage.getItem('expirationTime') : "";

    const remainingTime = calculateRemainingTime(storedExpirationTime);

    if (storedExpirationTime <= 3600) {
        typeof window !== "undefined" && localStorage.removeItem('googleToken');
        typeof window !== "undefined" && localStorage.removeItem('myToken');
        typeof window !== "undefined" && localStorage.removeItem('expirationTime');
        typeof window !== "undefined" && localStorage.removeItem('userName');
        return null;
    }

    return {
        googleToken: storedGoogleToken,
        myToken: storedMyToken,
        userName: storedUserName,
        duration: remainingTime,
    };
};

export const AuthContextProvider = (props) => {
    const data = retrieveStoredData();

    let initialGoogleToken;
    let initialMyToken;
    let initialUserName;

    if (data) {
        initialGoogleToken = data.googleToken;
        initialMyToken = data.myToken;
        initialUserName = data.userName;
    }

    const [googleToken, setGoogleToken] = useState(initialGoogleToken);
    const [myToken, setMyToken] = useState(initialMyToken);
    const [userName, setUserName] = useState(initialUserName);

    const userIsLoggedIn = !!googleToken && !!myToken && !!userName;

    const logoutHandler = useCallback(() => {
        setGoogleToken(null);
        setMyToken(null);
        setUserName(null);
        localStorage.removeItem('googleToken');
        localStorage.removeItem('myToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('expirationTime');

        if (logoutTimer) {
            clearTimeout(logoutTimer);
            if (typeof window !== 'undefined') {
                document.location.href = '/';
            }
        }
    }, []);

    const loginHandler = (googleToken, expirationTime, bestToken, userName) => {
        setGoogleToken(googleToken);
        setMyToken(bestToken);
        setUserName(userName);

        //axios.defaults.headers.common['Authorization'] = `Bearer ${bestToken}`;

        localStorage.setItem('googleToken', googleToken);
        localStorage.setItem('expirationTime', expirationTime);
        localStorage.setItem('userName', userName);
        localStorage.setItem('myToken', myToken);

        const remainingTime = calculateRemainingTime(expirationTime);

        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    useEffect(() => {
        if (data) {
            logoutTimer = setTimeout(logoutHandler, data.duration);
        }
    }, [data, logoutHandler]);

    const contextValue = {
        googleToken: googleToken,
        myToken: myToken,
        userName: userName,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
