import React, {useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import {CardContent, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useLocalStorage} from "../../libs/useLocalStorage";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";

var humanizeDuration = require('humanize-duration');

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box border={0}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));


export default function VehicleLease(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();


    const [vehiclename, setVehicleName] = useLocalStorage("vehicleName", "No Name");
    const [vehicleprice, setVehiclePrice] = useLocalStorage("vehicleprice", 10000);
    const [vehiclenew, setVehicleNew] = useLocalStorage("vehiclenew", true);
    const [monthlyfuel, setMonthlyFuel] = useLocalStorage("monthlyfuel", 500);
    const [monthlyoil, setMonthlyOil] = useLocalStorage("monthlyoil", 2.2);
    const [loanrate, setLoanRate] = useLocalStorage("loanrate", 6.0);
    const [downpayment, setDownpayment] = useLocalStorage("downpayment", 1000);
    const [rebate, setRebate] = useLocalStorage("rebate", 1000);

    const [leaseduration, setLeaseDuration] = useLocalStorage("leaseduration", 48);
    const onLeaseDurationChanged = (e) => setLeaseDuration(parseInt(e.target.value));

    const [leasepayment, setLeasePayment] = useLocalStorage("leasepayment", 700.50);
    const onLeasePaymentChanged = (e) => setLeasePayment(parseFloat(e.target.value));

    const [leasebuyout, setLeaseBuyOut] = useLocalStorage("leasebuyout", 20000);
    const onLeaseBuyOutChanged = (e) => setLeaseBuyOut(parseInt(e.target.value));

    const [leasedurationlimit, setLeaseDurationLimit] = useLocalStorage("leasedurationlimit", 60000);
    const onLeaseDurationLimitChanged = (e) => setLeaseDurationLimit(parseInt(e.target.value));

    const calcFutureValue = () => {
        // leases are always on new vehicles
        var newprice = vehicleprice - (vehicleprice * 0.1);
        for (let i = 0; i < (leaseduration / 12); i++) {
            newprice = newprice - (newprice * 0.1);
        }
        return newprice;
    }

    const calcMonthlyCost = () => {
        let sellprice = calcFutureValue();
        let frontload = leasepayment * leaseduration;
        let backload = leasebuyout;
        let purchaseprice = frontload + backload;
        let energy = (monthlyfuel + monthlyoil) * leaseduration;
        let monthly = (purchaseprice + energy - sellprice) / leaseduration;
        return monthly.toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">
                                Lease Details
                            </Typography>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="leaseduration" aria-describedby="my-helper-text"
                                           defaultValue={leaseduration}
                                           required
                                           fullWidth
                                           onChange={onLeaseDurationChanged}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Lease Term in Months</FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="leasepayment" aria-describedby="my-helper-text"
                                           defaultValue={leasepayment}
                                           required
                                           fullWidth
                                           onChange={onLeasePaymentChanged}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Monthly Payment $</FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="leasebuyout" aria-describedby="my-helper-text"
                                           defaultValue={leasebuyout}
                                           required
                                           fullWidth
                                           onChange={onLeaseBuyOutChanged}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Lease Buyout $</FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="leasedurationlimit" aria-describedby="my-helper-text"
                                           defaultValue={leasedurationlimit}
                                           required
                                           fullWidth
                                           onChange={onLeaseDurationLimitChanged}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Maximum Driving Distance</FormHelperText>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid container xs="12" spacing="1" height={30}>
                    </Grid>

                </Grid>
                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="8.25" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h5">
                                Lease Summary
                            </Typography>

                            <Typography variant="body">
                                <p>
                                    Based on historical industry depreciation, after {leaseduration} months this vehicle
                                    will
                                    have depreciated to a value of
                                    ${calcFutureValue().toLocaleString(undefined, {maximumFractionDigits: 2})} with
                                    a buyout of ${leasebuyout.toLocaleString(undefined, {maximumFractionDigits: 2})}
                                </p>

                                <p>
                                    The cost of driving this vehicle will be an
                                    estimated ${calcMonthlyCost()} if sold at {leaseduration} months when the
                                    lease expires.
                                </p>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>;
};