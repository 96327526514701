import React, {useContext} from "react";
import {styled, useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import {CardContent, FormControl, FormHelperText, Stack, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useLocalStorage} from "../../libs/useLocalStorage";
import Input from "@mui/material/Input";
import Switch from '@mui/material/Switch';

var humanizeDuration = require('humanize-duration');


const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


export default function VehicleGeneral(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();

    const [ismetric, setIsMetric] = useLocalStorage("ismetric", true);

    const [vehiclename, setVehicleName] = useLocalStorage("vehicleName", "No Name");
    const onVehicleNameChange = (e) => setVehicleName(e.target.value);

    const [vehiclenew, setVehicleNew] = useLocalStorage("vehiclenew", true);
    const onVehicleNewChange = (e) => setVehicleNew(e.target.checked);

    const [vehicleprice, setVehiclePrice] = useLocalStorage("vehicleprice", 10000);
    const onVehiclePriceChange = (e) => setVehiclePrice(parseInt(e.target.value));

    const [downpayment, setDownpayment] = useLocalStorage("downpayment", 1000);
    const onDownpaymentChange = (e) => setDownpayment(parseInt(e.target.value));

    const [rebate, setRebate] = useLocalStorage("rebate", 0);
    const onRebateChange = (e) => setRebate(parseInt(e.target.value));

    const [monthlydriving, setMonthlyDriving] = useLocalStorage("monthlydriving", 2000);
    const onMonthlyDrivingChange = (e) => setMonthlyDriving(parseInt(e.target.value));

    const [loanrate, setLoanRate] = useLocalStorage("loanrate", 6.0);
    const onLoanRateChange = (e) => setLoanRate(parseFloat(e.target.value));

    const [investrate, setInvestRate] = useLocalStorage("investrate", 10.0);
    const onInvestRateChange = (e) => setInvestRate(parseFloat(e.target.value));

    const [inflation, setInflation] = useLocalStorage("inflation", 5.0);
    const onInflationChange = (e) => setInflation(parseFloat(e.target.value));

    const [gasprice, setGasPrice] = useLocalStorage("gasprice", 2.2);
    const onGasPriceChange = (e) => setGasPrice(parseFloat(e.target.value));

    const [dieselprice, setDieselPrice] = useLocalStorage("gasprice", 2.1);
    const onDieselPriceChange = (e) => setDieselPrice(parseFloat(e.target.value));

    const [electricityprice, setElecticityPrice] = useLocalStorage("electricityprice", 0.09);
    const onElectricyChange = (e) => setElecticityPrice(parseFloat(e.target.value));

    const [monthlyfuel, setMonthlyFuel] = useLocalStorage("monthlyfuel", 500);
    const onMonthlyFuelChange = (e) => setMonthlyFuel(parseInt(e.target.value));

    const [monthlyoil, setMonthlyOil] = useLocalStorage("monthlyoil", 2.2);
    const onMonthlyOilChange = (e) => setMonthlyOil(parseInt(e.target.value));

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">
                                Vehicle Information
                            </Typography>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>Used</Typography>
                                        <AntSwitch id="vehiclenew"
                                                   checked={vehiclenew}
                                                   onChange={onVehicleNewChange}
                                                   inputProps={{'aria-label': 'ant design'}}
                                        />
                                        <Typography>New</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <br/>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="vehiclename" aria-describedby="my-helper-text"
                                           defaultValue={vehiclename}
                                           required
                                           fullWidth
                                           onChange={onVehicleNameChange}
                                    />
                                    <FormHelperText id="my-helper-text">Name of Vehicle</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="downpayment" aria-describedby="my-helper-text"
                                           defaultValue={downpayment}
                                           required
                                           fullWidth
                                           onChange={onDownpaymentChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Downpayment or Trade-ins</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="rebate" aria-describedby="my-helper-text"
                                           defaultValue={rebate}
                                           required
                                           fullWidth
                                           onChange={onRebateChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Rebates</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="vehicleprice" aria-describedby="my-helper-text"
                                           defaultValue={vehicleprice}
                                           required
                                           fullWidth
                                           onChange={onVehiclePriceChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Price of Vehicle</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="monthlydriving" aria-describedby="my-helper-text"
                                           defaultValue={monthlydriving}
                                           required
                                           fullWidth
                                           onChange={onMonthlyDrivingChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Monthly Driving</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="monthlyfuel" aria-describedby="my-helper-text"
                                           defaultValue={monthlyfuel}
                                           required
                                           fullWidth
                                           onChange={onMonthlyFuelChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Monthly Fuel/Electricity $</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="monthlyoil" aria-describedby="my-helper-text"
                                           defaultValue={monthlyoil}
                                           required
                                           fullWidth
                                           onChange={onMonthlyOilChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Monthly Oil Change $</FormHelperText>
                                </Grid>
                            </Grid>


                        </CardContent>
                    </Card>

                </Grid>


                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>

                <Grid container xs="3" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h6">
                                Financial Markets
                            </Typography>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="loanrate" aria-describedby="my-helper-text"
                                           defaultValue={loanrate}
                                           required
                                           fullWidth
                                           onChange={onLoanRateChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Loan Interest Rate %</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="investrate" aria-describedby="my-helper-text"
                                           defaultValue={investrate}
                                           required
                                           fullWidth
                                           onChange={onInvestRateChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Investment Return Rate %</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inflation" aria-describedby="my-helper-text"
                                           defaultValue={inflation}
                                           required
                                           fullWidth
                                           onChange={onInflationChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Annual Inflation %</FormHelperText>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>

                <Grid container xs="3" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h6">
                                Energy Prices
                            </Typography>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="gasprice" aria-describedby="my-helper-text"
                                           defaultValue={gasprice}
                                           required
                                           fullWidth
                                           onChange={onGasPriceChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Gasoline Price</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="dieselprice" aria-describedby="my-helper-text"
                                           defaultValue={dieselprice}
                                           required
                                           fullWidth
                                           onChange={onDieselPriceChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Diesel Price</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="electricityprice" aria-describedby="my-helper-text"
                                           defaultValue={electricityprice}
                                           required
                                           fullWidth
                                           onChange={onElectricyChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Electricity Price per Kwh</FormHelperText>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>
        ;
};