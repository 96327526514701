import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {AuthenticateButton} from "./styles";

import AuthContext from '../../store/auth-context';
import {makeStyles, withStyles} from '@mui/styles';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import {IconButton} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import logo from './tussik.png';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import PageContext from "../../store/page-context";
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';


const WhiteTextTypography = withStyles({
    root: {
        color: "#FFFFFF",
        paddingLeft: "1rem"
    }
})(Typography);

const useStyles = makeStyles((theme) => ({

    header: {
        height: "4rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0% 1%"
    },

    logo: {
        width: "10rem",
    },

}));

const Header = () => {

    const pageCtx = useContext(PageContext);
    const authCtx = useContext(AuthContext);

    const isLoggedIn = authCtx.isLoggedIn;

    const logoutHandler = () => {
        authCtx.logout();
        handleClose();
    };

    const userName = authCtx.userName;
    const title = pageCtx.title;

    const classes = useStyles();


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getInitials = (name, maxLength = 3) => {

        const chars = [...name.trim()];

        if (name.length <= maxLength) return name;

        const initials = [];

        for (const [idx, char] of chars.entries()) {
            if (
                char.toLowerCase() !== char ||
                !chars[idx - 1] ||
                /\s/.test(chars[idx - 1])
            ) {
                initials.push(char);

                if (initials.length === maxLength) break;
            }
        }

        return initials.join('');
    };

    if (isLoggedIn === false) {
        return (
            <AppBar style={{background: "#2a2f34"}} position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <header className={classes.header}>
                    <Link to='/'>
                        <div><img className={classes.logo} src={logo} alt={"Tussik"}/>
                        </div>
                    </Link>
                    <Typography
                        color="inherit" variant="h5"
                        component="div">
                        {title}
                    </Typography>
                    <div>
                        BETA
                        {/*<AuthenticateButton as={Link} to="/login">Login or Register</AuthenticateButton>*/}
                    </div>
                </header>
            </AppBar>
        );
    }


    return (
        <AppBar style={{background: "#2a2f34"}} position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <header className={classes.header}>
                <Link to='/'>
                    <div><img className={classes.logo} src={logo} alt={"Tussik"}/>
                    </div>
                </Link>
                <Typography
                    color="inherit" variant="h5"
                    component="div">
                    {title}
                </Typography>
                {/*<div>*/}
                {/*    <IconButton*/}
                {/*        aria-controls="menu-appbar"*/}
                {/*        aria-haspopup="true"*/}
                {/*        onClick={handleMenu}*/}
                {/*    >*/}
                {/*        <Avatar>*/}
                {/*            {getInitials(userName)}*/}
                {/*        </Avatar>*/}
                {/*        <WhiteTextTypography variant="subtitle1">*/}
                {/*            {userName}*/}
                {/*        </WhiteTextTypography>*/}
                {/*    </IconButton>*/}
                {/*    <Menu*/}
                {/*        id="menu-appbar"*/}
                {/*        anchorEl={anchorEl}*/}
                {/*        keepMounted*/}
                {/*        open={open}*/}
                {/*        onClose={handleClose}*/}
                {/*    >*/}
                {/*        <MenuItem onClick={logoutHandler}>*/}
                {/*            <ListItemIcon>*/}
                {/*                <Logout fontSize="small"/>*/}
                {/*            </ListItemIcon>*/}
                {/*            Logout*/}
                {/*        </MenuItem>*/}

                {/*    </Menu>*/}
                {/*</div>*/}
            </header>
        </AppBar>
    );
};

export default Header;
