import styled from 'styled-components';
import bgImg from './home.jpg';

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background: url(${bgImg}) center no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
`;

export const Inner = styled.div`
  margin: 0 auto;
  width: 1100px;
  max-width: 100%;
  z-index: 1;
`;

export const Logo = styled.img`
  position: absolute;
  top: 2em;
  left: 2em;
  width: 60px;
`;

export const BottomDivider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 96px;

  > svg {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;



