import React, {Fragment, useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import Input from '@mui/material/Input';
import {CardContent, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useLocalStorage} from "../../libs/useLocalStorage";


var moment = require('moment');
var humanizeDuration = require('humanize-duration');


// https://www.jcalc.net/battery-size-calculator

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));


export default function CalcConsumptionRate(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();

    const [cellseries, setCellSeriesValue] = useLocalStorage("cellseries", 8);
    const onCellSeriesChange = (e) => setCellSeriesValue(parseInt(e.target.value));

    const [parallel, setParallelValue] = useLocalStorage("parallel", 1);
    const onParallelChange = (e) => setParallelValue(parseInt(e.target.value));

    const [cellah, setCellAhValue] = useLocalStorage("cellah", 230);
    const onCellAhChange = (e) => setCellAhValue(parseFloat(e.target.value));

    const [cellvolts, setCellVoltValue] = useLocalStorage("cellvolts", 3.2);
    const onCellVoltChange = (e) => setCellVoltValue(parseFloat(e.target.value));

    const [outputwh, setWattHourTotalValue] = useLocalStorage("dischargewh", 40);

    const [outputw1h, setWatt1HourValue] = useLocalStorage("consumew1h", 40);
    const onOutputW1hChange = (e) => {
        setWatt1HourValue(parseInt(e.target.value));
        setWattHourTotalValue(parseInt(e.target.value) + (outputw24h / 24) + (outputw12h / 12));
    }

    const [outputw24h, setWatt24HourValue] = useLocalStorage("consumew24h", 0);
    const onOutputW24hChange = (e) => {
        setWatt24HourValue(parseInt(e.target.value));
        setWattHourTotalValue((parseInt(e.target.value) / 24) + (outputw12h / 12) + outputw1h);
    }

    const [outputw12h, setWatt12HourValue] = useLocalStorage("consumew12h", 0);
    const onOutputW12hChange = (e) => {
        setWatt12HourValue(parseInt(e.target.value));
        setWattHourTotalValue((parseInt(e.target.value) / 12) + (outputw24h / 24) + outputw1h);
    }

    const [highestcharge, setHighestChargeValue] = useLocalStorage("highestcharge", 90);
    const onHighestChargeChange = (e) => setHighestChargeValue(Math.max(lowestcharge, parseInt(e.target.value)));

    const [lowestcharge, setLowestChargeValue] = useLocalStorage("lowestcharge", 20);
    const onLowestChargeChange = (e) => setLowestChargeValue(Math.min(highestcharge, parseInt(e.target.value)));


    const batteryvolts = () => {
        // let n = (cellcount * cellvolts).toFixed(0);
        // if (n < 12) return (cellcount * cellvolts).toFixed(1);
        // if (n < 24) return 12;
        // if (n < 48) return 24;
        // if (n < 55) return 48;
        return (cellseries * cellvolts).toFixed(1);
    }

    const capacity = () => {
        let volts = cellseries * cellvolts;
        return ((cellah * volts * parallel) / 1000).toFixed(2);
    }

    const capacity_usable = () => {
        let capacity = cellah * parallel * cellseries * cellvolts;
        let lo = Math.min(100, Math.max(0, lowestcharge));
        let hi = Math.max(0, Math.min(100, highestcharge));

        let low_cut = capacity * (lo / 100);
        let top_cut = capacity * ((100 - hi) / 100);
        capacity = capacity - low_cut - top_cut;
        return (capacity / 1000).toFixed(2);
    }

    const normal_amps = () => {
        let basewatts = outputwh * Math.max(1, 1);
        let volts = (cellseries * cellvolts).toFixed(0);
        return (basewatts / volts).toFixed(0) + "A";
    }

    const dischargerange = () => {
        let capacity = cellah * parallel * cellseries * cellvolts;
        let lo = Math.min(100, Math.max(0, lowestcharge));
        let hi = Math.max(0, Math.min(100, highestcharge));

        let low_cut = capacity * (lo / 100);
        let top_cut = capacity * ((100 - hi) / 100);
        capacity = capacity - low_cut - top_cut;
        capacity = capacity * 0.8; // normal efficiency reduction

        let hours = capacity / outputwh;
        let sec = Math.floor(3600 * hours);
        if (sec > 86400)
            return humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h'], round: true});
        else
            return humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true});
    }

    const dischargeWatts = (hours) => {
        let basewatts = outputwh * Math.max(1, hours);
        if (basewatts % 1000 === 0) return (basewatts / 1000).toFixed(0) + " kWh";
        if (basewatts > 1000) return (basewatts / 1000).toFixed(2) + " kWh";
        return basewatts.toFixed(0) + " Wh";
    }

    const maxdischarge = () => {
        return (cellah * 0.09).toFixed(0);
    }

    const is_a_battery = () => {
        return cellseries > 0 && parallel > 0;
    }

    const is_consumption_ready = () => {
        return outputwh > 0 && lowestcharge >= 0 && highestcharge > lowestcharge;
    }

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h6">
                                Consumption
                            </Typography>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputamps" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           onChange={onOutputW1hChange}
                                           defaultValue={outputw1h}
                                    />
                                    <FormHelperText id="my-helper-text">Watts Every Hour</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputamps" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           onChange={onOutputW24hChange}
                                           defaultValue={outputw24h}
                                    />
                                    <FormHelperText id="my-helper-text">Watts Once Per Day</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputamps" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           onChange={onOutputW12hChange}
                                           defaultValue={outputw12h}
                                    />
                                    <FormHelperText id="my-helper-text">Watts Twice Per Day</FormHelperText>
                                </Grid>
                            </Grid>


                        </CardContent>
                    </Card>
                </Grid>
                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="6" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h6">
                                Battery Capacity
                            </Typography>

                            {is_a_battery() ?
                                <Fragment>
                                    <Typography variant="body">{is_consumption_ready() ?
                                        <p>
                                            This is a {batteryvolts()}v battery with {capacity()} kWh capacity,
                                            and {capacity_usable()} kWh of usable capacity.
                                        </p> :
                                        <p>
                                            This is a {batteryvolts()}v battery with {capacity()} kWh capacity.
                                        </p>
                                    }

                                    </Typography>

                                    <Typography variant="h6">
                                        Consumption
                                    </Typography>

                                    {is_consumption_ready() ? <Fragment>
                                            <Typography variant="body">
                                                <p>
                                                    At a rate of <b>{dischargeWatts(1)}</b>, this battery will
                                                    last <b>{dischargerange()}</b>. Check the cell specifications to
                                                    determine the maximum amperage during discharge, known as the
                                                    discharge rate.
                                                </p>
                                                <p>
                                                    Over 24 hours discharge will be <b>{dischargeWatts(24)}</b>.
                                                    The average home in North America uses 30 kWh of electricity per
                                                    day, or 1250W per hour.
                                                </p>
                                                <p>
                                                    The load will require <b>{normal_amps()}</b> of
                                                    power from the {batteryvolts()}V battery.
                                                </p>
                                                <p>
                                                    Converting DC power to AC does have an efficiency cost that we have
                                                    set to 80% based on standard measures.
                                                </p>
                                            </Typography>
                                        </Fragment> :
                                        <Typography variant="body">
                                            <p>
                                                Add the consumption rate and the safety range of the battery to get
                                                started.
                                            </p>
                                        </Typography>}
                                </Fragment> :
                                <Typography variant="body">
                                    <p>
                                        Setup at least one cell series and one in parallel, with its volts and amp hours
                                        to get started
                                    </p>
                                </Typography>
                            }

                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>;
};