import React, {useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContainer from "../components/ui/PageContainer";
import PageContext from "../store/page-context";
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CalcChargeTime from "./electrical/battery";
import CalcConsumptionRate from "./electrical/capacity";
import Solar from "./electrical/solar";
import {useNavigate} from "react-router";
import ReactGA from "react-ga4";
import CalcCharging from "./electrical/charging";

/*

CALCULATORS
    -> PV potential in Canada by month by GPS
    -> Battery cell capacity calculator
    -> AC demand calculator
    ->

 */

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    tabpanels: {
        flexGrow: 1
    }

}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const tab_table = [
    {"token": "battery", "name": "Battery", "render": <CalcChargeTime/>},
    {"token": "capacity", "name": "Capacity", "render": <CalcConsumptionRate/>},
    {"token": "charging", "name": "Charging", "render": <CalcCharging/>},
    {"token": "solar", "name": "Solar", "render": <Solar/>},
];

export default function Electricity(props) {
    const pageCtx = useContext(PageContext);
    const history = useNavigate();
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});


    // const history = useNavigate();
    pageCtx.updateTitle("Electricity Calculators");

    let deftab = 0;
    for (let i = 0; i < tab_table.length; i++) {
        if (props.tab === tab_table[i].token) {
            deftab = i;
            break;
        }
    }
    //history({ pathname: `/electric/${tab_table[deftab].token}`});
    //history(`/electric/${tab_table[deftab].token}`);


    const classes = useStyles();

    const theme = useTheme();
    const [value, setValue] = React.useState(deftab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history(`/electric/${tab_table[newValue].token}`);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
        // console.log("HISTORY: ev " + window.location.pathname + window.location.search);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return <PageContainer>
        <Box sx={{bgcolor: 'background.paper'}}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs"
                >
                    {tab_table.map((tab, idx) =>
                        <Tab label={tab.name} {...a11yProps(idx)}/>
                    )}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {tab_table.map((tab, idx) =>
                    <TabPanel value={value} index={idx} dir={theme.direction}>
                        {tab.render}
                    </TabPanel>
                )}
            </SwipeableViews>
        </Box>
    </PageContainer>;
};