import React, {useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import {CardContent, FormControl, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useLocalStorage} from "../../libs/useLocalStorage";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

var humanizeDuration = require('humanize-duration');

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box border={0}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));


export default function VehicleCash(props) {
    const pageCtx = useContext(PageContext);

    const classes = useStyles();

    const theme = useTheme();


    const [vehiclename, setVehicleName] = useLocalStorage("vehicleName", "No Name");
    const [vehicleprice, setVehiclePrice] = useLocalStorage("vehicleprice", 10000);
    const [vehiclenew, setVehicleNew] = useLocalStorage("vehiclenew", true);
    const [monthlyfuel, setMonthlyFuel] = useLocalStorage("monthlyfuel", 500);
    const [monthlyoil, setMonthlyOil] = useLocalStorage("monthlyoil", 2.2);
    const [loanrate, setLoanRate] = useLocalStorage("loanrate", 6.0);
    const [downpayment, setDownpayment] = useLocalStorage("downpayment", 1000);
    const [rebate, setRebate] = useLocalStorage("rebate", 1000);

    const [investrate, setInvestRate] = useLocalStorage("investrate", 10.0);

    const calcFutureValue = () => {
        var newprice = vehicleprice;
        if (vehiclenew) newprice = newprice - (newprice * 0.1);
        for (let i = 0; i < 4; i++) {
            newprice = newprice - (newprice * 0.1);
        }
        return newprice;
    }

    const calcMonthlyCost = () => {
        let sellprice = calcFutureValue();
        let purchaseprice = vehicleprice - rebate;
        let energy = (monthlyfuel + monthlyoil) * 48;
        let monthly = (purchaseprice + energy - sellprice) / 48;
        return monthly.toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    const calcInvestGain = () => {
        let value = vehicleprice - rebate;
        let rate = investrate / 100 / 12;
        for (let i = 0; i < 48; i++) {
            value += value * rate;
        }
        console.log("rate " + rate.toString());
        console.log("value " + (vehicleprice - rebate).toString());
        let profit = value - vehicleprice - rebate;
        return value.toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">
                                Cash Purchase
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="8.25" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h5">
                                Cash Summary
                            </Typography>

                            <Typography variant="body">
                                <p>
                                    Based on historical industry depreciation, after 48 months this vehicle will
                                    have depreciated to a value of
                                    ${calcFutureValue().toLocaleString(undefined, {maximumFractionDigits: 2})}
                                </p>

                                <p>
                                    The cost of driving this vehicle will be an
                                    estimated ${calcMonthlyCost()} if sold at 48 months after purchase.
                                </p>

                                {/*<p>*/}
                                {/*    Consider borrowing the money at {loanrate}% and investing the cash*/}
                                {/*    for a gain of {investrate}%. Over 48 months the*/}
                                {/*    potential gain of ${calcInvestGain()}*/}
                                {/*</p>*/}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </FormControl>
    </React.Fragment>;
};