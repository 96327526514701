import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {SnackbarProvider} from "./libs/toast";
import {LiveUpdateProvider} from "./libs/liveUpdate";

ReactDOM.render(
    <React.Fragment>
    {/*<SnackbarProvider>*/}
    {/*    <LiveUpdateProvider>*/}
            {/*<AuthContextProvider>*/}
            {/*    <PageContextProvider>*/}
            <App/>
            {/*</PageContextProvider>*/}
            {/*</AuthContextProvider>*/}
        {/*</LiveUpdateProvider>*/}
     {/*</SnackbarProvider>*/}
        </React.Fragment>
    ,
    document.getElementById('root')
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
