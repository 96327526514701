import React, {useCallback, useContext, useState} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import Input from '@mui/material/Input';
import {CardContent, FormControl, FormHelperText, LinearProgress, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import SolarCalculator from "../../libs/solarcalc";
import {useLocalStorage} from "../../libs/useLocalStorage";
import {VictoryChart, VictoryLegend, VictoryLine} from "victory";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

var humanizeDuration = require('humanize-duration');

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


// https://www.jcalc.net/battery-size-calculator
// https://www.wirebarn.com/Wire-Calculator-_ep_41.html

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box border={0}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));


export default function Solar(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();

    const [finding, setFinding] = useState(false);

    const [pvdata, setPVDataValue] = useState(null);
    const [sundata, setSunDataValue] = useState(null);

    const [monthlykWh, setMonthlykWhValue] = useLocalStorage("monthlykWh", 600);
    const onkWhChange = (e) => setMonthlykWhValue(parseInt(e.target.value));

    const [coverage, setCoverageValue] = useLocalStorage("coverage", 1);
    const onCoverageChange = (e) => setCoverageValue(parseInt(e.target.value));

    const [lng, setLngValue] = useLocalStorage("lng", 0);
    const onLngChange = (e) => setLngValue(parseInt(e.target.value));

    const [lat, setLatValue] = useLocalStorage("lat", 0);
    const onLatChange = (e) => setLatValue(parseFloat(e.target.value));

    const [tab, setTabValue] = React.useState(0);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    const onFindLocation = (e) => {
        setFinding(true);
        navigator.geolocation.getCurrentPosition(function (position) {
            setLatValue(position.coords.latitude);
            setLngValue(position.coords.longitude);
            setFinding(false);
        });
    }

    const sunlight = () => {
        return SolarCalculator.sun_hours_bymonth(lng, lat);
    }

    const sunmonth = (sec, month) => {
        let months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        let line = humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h'], round: true});
        return <p>{months[month]} has {line} of day light</p>
    }

    const mapurl = () => {
        const token = "sk.eyJ1IjoiZGFycmVudHVzc2lrIiwiYSI6ImNreWdxZTk1NzBveGcyb212MjRwZmp6NTIifQ.CHT1pbkPuIm9KDRaZ4klBg";
        let marker = `pin-l+4164fb(${lng},${lat})/${lng},${lat},14,0`;
        let url = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/" + marker;
        url += "/600x300@2x?before_layer=state-label&logo=false&access_token=" + token;
        return url;
    }

    const chartData = () => {
        const sun = SolarCalculator.sun_hours_year(lng, lat);
        const year = new Date().getFullYear();
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let sundata = [];
        for (let index = 0; index < sun.length; ++index) {

            let pv = 0.0;
            if (pvdata !== null) {
                try {
                    pv = pvdata.outputs.solrad_monthly[index];
                    //pv = parseFloat(pvdata.outputs.solrad_monthly).toFixed(2);
                } catch (e) {
                    pv = 0.0;
                }
            }

            sundata.push({
                date: new Date(year, index, 1),
                month: months[index],
                sunlight: (sun[index] / 3600).toFixed(2),
                pv: pv
            });
        }
        return sundata;
    }

    const loadPVdata = useCallback(() => {
        const url = SolarCalculator.pvwatt_url(lng, lat);
        // setPVDataValue({msg: url});
        axios.get(url).then((response) => {
            setPVDataValue(response.data);
        }).catch((error) => {
            // setPVDataValue({error: error});
        });
    }, [lat, lng]);

    React.useEffect(() => {
        const sun = SolarCalculator.sun_hours_year(lng, lat);
        let results = [];
        for (let index = 0; index < sun.length; ++index) {
            results.push({
                month: index,
                sunlight: sun[index]
            });
        }
        setSunDataValue(results);
        loadPVdata();
    }, [loadPVdata, lng, lat]);

    const calcSunHours = () => {
        var data = SolarCalculator.sun_hours_year(lng, lat);
        return Math.min.apply(null, data) / 3600;
    }

    const formatPower = (watts, multiplier) => {
        var capacity = watts * multiplier * 1000;
        if (capacity < 1000) {
            return capacity.toLocaleString('en-US', {maximumFractionDigits: 0}) + " Wh";
        }

        if (capacity < 1000000) {
            capacity = capacity / 1000;
            return capacity.toLocaleString('en-US', {maximumFractionDigits: 2}) + " kWh";
        }

        capacity = capacity / 1000000;
        return capacity.toLocaleString('en-US', {maximumFractionDigits: 2}) + " MWh";
    }

    const calcBatterySize = (multiplier, nopvarray) => {
        var capacity = 0;

        if (nopvarray === false) {
            let cap_req = (monthlykWh / 30);
            let pv_collected = (cap_req / 24) * calcSunHours();
            capacity = cap_req - pv_collected;
        } else {
            capacity = monthlykWh / 30;
        }
        return formatPower(capacity * coverage, multiplier);
    }

    const calcShortestSun = () => {
        var capacity = 0;
        var low_month = null;
        var low_sun = 24;
        var no_sun = 24;

        let daily_capacity = monthlykWh / 30;
        let hourly_capacity = daily_capacity / 24;

        if (sundata === null) return "n/a";

        no_sun = calcSunHours();

        return no_sun.toLocaleString('en-US', {maximumFractionDigits: 0}) + " hours";
    }

    const calcHourlyConsumption = () => {
        return formatPower(monthlykWh / 30 / 24, 1);
    }

    const calcDailyConsumption = () => {
        return formatPower(monthlykWh / 30, 1);
    }

    const calcMonthlyConsumption = () => {
        return formatPower(monthlykWh, 1);
    }

    const calcNightConsumption = () => {
        let n = (monthlykWh / 30 / 24) * (24 - calcSunHours());
        return formatPower(n, 1);
    }

    const calcPVSize = () => {
        // how much coverage do we require
        // divide by number of hours in the sun
        // that is how much the array needs to support daily
        // TODO: add capacity to fill battery during the day as well
        //let cap_req = (monthlykWh / 30 / 24) * (24 - calcSunHours());
        let cap_req = (monthlykWh / 30);
        let pv_req = cap_req / calcSunHours();
        return formatPower(pv_req, 1);
    }

    const calcPVCount = (panelsize) => {
        // how much coverage do we require
        // divide by number of hours in the sun
        // that is how much the array needs to support daily
        // TODO: add capacity to fill battery during the day as well
        //let cap_req = (monthlykWh / 30 / 24) * (24 - calcSunHours()) * 1000;
        let cap_req = (monthlykWh / 30) * 1000;
        let pv_req = cap_req / calcSunHours();
        let pan_req = pv_req / panelsize;
        return pan_req.toLocaleString('en-US', {maximumFractionDigits: 0});
    }

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">
                                Location
                            </Typography>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={onFindLocation}
                                    >
                                        Get Location
                                    </Button>
                                    {finding && <LinearProgress/>}
                                </Grid>
                            </Grid>
                            <Grid container xs="12" spacing="1" height={20}>
                            </Grid>
                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="lat" aria-describedby="my-helper-text"
                                           defaultValue={lat}
                                           value={lat}
                                           required
                                           fullWidth
                                           onChange={onLatChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Latitude</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="parallel" aria-describedby="my-helper-text"
                                           defaultValue={lng}
                                           value={lng}
                                           required
                                           fullWidth
                                           onChange={onLngChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Longitude</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="parallel" aria-describedby="my-helper-text"
                                           defaultValue={monthlykWh}
                                           value={monthlykWh}
                                           required
                                           fullWidth
                                           onChange={onkWhChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Monthly kWh Used</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="parallel" aria-describedby="my-helper-text"
                                           defaultValue={coverage}
                                           value={coverage}
                                           required
                                           fullWidth
                                           onChange={onCoverageChange}
                                           type="number"
                                    />
                                    <FormHelperText id="my-helper-text">Days Coverage</FormHelperText>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>

                    <Grid container xs="12" spacing="1" height={30}>
                    </Grid>

                </Grid>
                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="8.5" spacing="1" display="inline-block">
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tab} onChange={onTabChange} aria-label="basic tabs example">
                            <Tab label="Location" {...a11yProps(0)} />
                            <Tab label="Solar" {...a11yProps(1)} />
                            <Tab label="PV Table" {...a11yProps(2)} />
                            <Tab label="Profile" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tab} index={0}>
                        {(lat !== 0 && lng !== 0) && <img src={mapurl()}/>}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <VictoryChart
                            height={230}
                            maxDomain={{y: 24}}
                            minDomain={{y: 0}}
                            // name="Sunlight"
                            //theme={VictoryTheme.grayscale}
                            padding={{top: 10, bottom: 40, left: 30, right: 20}}
                        >
                            <VictoryLegend x={40} y={10}
                                // title="Legend"
                                           centerTitle
                                           orientation="horizontal"
                                           gutter={20}
                                           style={{border: {stroke: "black"}, title: {fontSize: 20}}}
                                           data={[
                                               {name: "Sun Hours", symbol: {fill: "orange"}},
                                               {name: "kWh / m² / day", symbol: {fill: "#c43a31"}}
                                           ]}
                            />
                            <VictoryLine
                                animate={{
                                    duration: 2000,
                                    onLoad: {duration: 1000}
                                }}

                                style={{
                                    data: {stroke: "orange"},
                                    parent: {border: "1px solid #ccc"}
                                }}

                                x="month"
                                y={(rec) => Math.floor(rec.sunlight)}
                                // sortKey="date"
                                // range={{y:[0,24]}}
                                interpolation="natural"
                                // labels={({datum}) => Math.floor(datum.sunlight)}
                                // labelComponent={<VictoryLabel renderInPortal dy={-20}/>}
                                data={chartData()}
                                label="Hours"
                            />

                            <VictoryLine
                                animate={{
                                    duration: 2000,
                                    onLoad: {duration: 1000}
                                }}

                                style={{
                                    data: {stroke: "#c43a31"},
                                    parent: {border: "1px solid #ccc"}
                                }}

                                x="month"
                                y="pv"
                                // sortKey="date"
                                // range={{y:[0,24]}}
                                interpolation="natural"
                                // labels={({datum}) => Math.floor(datum.sunlight)}
                                // labelComponent={<VictoryLabel renderInPortal dy={-20}/>}
                                data={chartData()}
                                label="Hours"
                            />
                        </VictoryChart>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Month</TableCell>
                                        <TableCell align="right">kWh / m² / day</TableCell>
                                        <TableCell align="right">AC kWh</TableCell>
                                        <TableCell align="right">Hours of Sun</TableCell>
                                        {/*<TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pvdata && pvdata.outputs.solrad_monthly.map((row, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {monthNames[idx]}
                                            </TableCell>
                                            <TableCell align="right">{row.toFixed(2)}</TableCell>
                                            <TableCell align="right">
                                                {pvdata.outputs.ac_monthly[idx].toFixed(0)}
                                            </TableCell>
                                            <TableCell align="right">
                                                {/*{ JSON.stringify(sundata)}*/}
                                                {sundata !== null && (sundata[idx].sunlight / 3600).toFixed(1)}
                                            </TableCell>
                                            {/*<TableCell align="right">{row.carbs}</TableCell>*/}
                                            {/*<TableCell align="right">{row.protein}</TableCell>*/}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <Card variant="outlined" display="inline-block">
                            <CardContent>
                                <Typography variant="h5">
                                    Estimation of PV and battery requirements
                                </Typography>
                                <Typography variant="body">
                                    Based on your location
                                    and lowest PV capture month
                                    <ul>
                                        <li>Shortest amount of sunlight is {calcShortestSun()}</li>
                                        <li>Average consumption per month {calcMonthlyConsumption()}</li>
                                        <li>Average consumption per day {calcDailyConsumption()}</li>
                                        <li>Average consumption per hour {calcHourlyConsumption()}</li>
                                        <li>Heaviest evening consumption {calcNightConsumption()}</li>

                                    </ul>
                                </Typography>
                                <Typography variant="body">
                                    <p>
                                        A solar array of <b>{calcPVSize()}</b> or more is recommended.
                                        This would require {calcPVCount(250)} panels at 250W each.
                                    </p>
                                </Typography>
                                <Typography variant="body">
                                    <p>
                                        Battery capacity of <b>{calcBatterySize(1, false)}</b> or more is recommended.
                                        A typical LiFePo4 battery safely operates between 20 and 90% of full capacity,
                                        while a Lead Acid battery operates between 50 and 100%. Based on these
                                        common usage ranges:
                                    </p>
                                    <ul>
                                        <li>LiFePo4 size of {calcBatterySize(1.30, false)} or larger</li>
                                        <li>Lead Acid size of {calcBatterySize(2.00, false)} or larger</li>
                                    </ul>
                                </Typography>

                                <Typography variant="h5">
                                    Estimation battery only and no PV array
                                </Typography>
                                <Typography variant="body">
                                    <p>
                                        Battery capacity of <b>{calcBatterySize(1, true)}</b> or more is recommended.
                                        A typical LiFePo4 battery safely operates between 20 and 90% of full capacity,
                                        while a Lead Acid battery operates between 50 and 100%. Based on these
                                        common usage ranges:
                                    </p>
                                    <ul>
                                        <li>LiFePo4 size of {calcBatterySize(1.30, true)} or larger</li>
                                        <li>Lead Acid size of {calcBatterySize(2.00, true)} or larger</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                    </TabPanel>
                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>;
};