import React, {useContext} from 'react'

import './app.css'
import AuthContext from "./store/auth-context";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Vehicles from "./pages/vehicles/vehicles";
import Electricity from "./pages/electricity";
import About from "./pages/about";
import Blog from "./pages/blog";
import Links from "./pages/links";
import SoftwareTools from "./pages/software";
import ReactGA from "react-ga4";


export default function MyRoutes(props) {

    const authCtx = useContext(AuthContext);
    const isLoggedIn = authCtx.isLoggedIn;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

    return (
        <Routes>
            {/*<Route path='/' exact element={isLoggedIn ? <Navigate to='/vehicles'/> : <Home/>}/>*/}
            <Route path='/' exact element={<Home/>}/>
            <Route path='/login' element={isLoggedIn ? <Navigate to='/'/> : <Login/>}/>

            <Route path='/vehicles' element={<Vehicles/>}/>
            <Route path='/vehicles/general' element={<Vehicles tab="general"/>}/>
            <Route path='/vehicles/cash' element={<Vehicles tab="cash"/>}/>
            <Route path='/vehicles/lease' element={<Vehicles tab="lease"/>}/>
            <Route path='/vehicles/loan' element={<Vehicles tab="loan"/>}/>

            <Route path='/links' element={<Links/>}/>

            <Route path='/electric' element={<Electricity/>}/>
            <Route path='/electric/battery' element={<Electricity tab="battery"/>}/>
            <Route path='/electric/topbalance' element={<Navigate to='/electric/battery'/>}/>
            <Route path='/electric/capacity' element={<Electricity tab="capacity"/>}/>
            <Route path='/electric/consumption' element={<Navigate to='/electric/capacity'/>}/>
            <Route path='/electric/charging' element={<Electricity tab="charging"/>}/>
            <Route path='/electric/solar' element={<Electricity tab="solar"/>}/>
            <Route path='/electric/pvcalc' element={<Navigate to='/electric/solar'/>}/>

            <Route path='/software' element={<SoftwareTools/>}/>
            <Route path='/software/epoch' element={<SoftwareTools tab="epoch"/>}/>
            <Route path='/software/date' element={<SoftwareTools tab="date"/>}/>

            <Route path='/about' element={<About/>}/>
            <Route path='/blog' element={<Blog/>}/>
            <Route path='/secure' element={isLoggedIn ? <Vehicles/> : <Navigate to='/'/>}/>
            <Route path='*' element={<Navigate to='/'/>}/>
        </Routes>
    )
}

