import React, {useCallback, useContext} from "react";
import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {CardActionArea, CardContent} from '@mui/material';
import Grid from '@mui/material/Grid';
import PageContainer from "../components/ui/PageContainer";
import PageContext from "../store/page-context";
import {makeStyles} from '@mui/styles';
import axios from "axios";
import ReactGA from "react-ga4";


const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

}));

const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function LinkCard(props) {
    return (
        <Card sx={{maxWidth: 345}}>
            <CardActionArea href={props.url} target="_blank" component="a">
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.desc}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

function LinkGroup(props) {
    const classes = useStyles();

    return (<React.Fragment>

        <Paper>
            <Typography variant="h5" component="div" align="center" className={classes.groupheader}>
                {props.title}
            </Typography>
        </Paper>
        <br/>
        <br/>

        <div className={classes.itemgrid}>
            <Grid container spacing={3} columnGap={3} rowGap={3} align="center" item>
                {props.children}
            </Grid>
        </div>

        <br/><br/>

    </React.Fragment>);
}

export default function Links(props) {
    const pageCtx = useContext(PageContext);
    pageCtx.updateTitle("Links");

    const classes = useStyles();

    const [linkdata, setLinkData] = React.useState([]);

    const loadLinkData = useCallback(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
        // console.log("HISTORY: links " + window.location.pathname + window.location.search)

        axios.get('/links.json', {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {

            setLinkData(response.data);

            // isLoading(false);

        }).catch((error) => {
            // setError(error.toString());

        });

    }, []);

    React.useEffect(() => {
        loadLinkData();
    }, [loadLinkData]);

    return <PageContainer>
        <div className={classes.content}>
            {linkdata.map((group) => {
                return (
                    <LinkGroup title={group.title}>
                        {group.links.map((link) => {
                            return (
                                <LinkCard title={link.title} url={link.url} desc={link.desc}/>
                            );
                        })}
                    </LinkGroup>
                );
            })};
        </div>
    </PageContainer>;
};