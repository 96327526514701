import {Toolbar} from '@mui/material';
import {NavLink} from "react-router-dom";
import React, {Fragment, useContext} from "react";
import AuthContext from "../../store/auth-context";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Header from "./Header";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import BoltIcon from '@mui/icons-material/Bolt';
import LinkIcon from '@mui/icons-material/Link';
import CodeIcon from '@mui/icons-material/Code';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

const drawerWidth = 185;

const MyDrawer = () => {

    const authCtx = useContext(AuthContext);

    const isLoggedIn = authCtx.isLoggedIn;

    return (
        <Fragment>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>

                {/*{isLoggedIn && <Header/>}*/}
                <Header/>


                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                    }}
                >

                    <Toolbar/>

                    <Box sx={{overflow: 'auto'}}>


                        <List>
                            <ListItem button component={NavLink} to="/electric" activeClassName="Mui-selected">
                                <ListItemIcon>
                                    <BoltIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Electricity"/>
                            </ListItem>
                        </List>
                        <Divider/>

                        <List>
                            <ListItem button component={NavLink} to="/vehicles" activeClassName="Mui-selected">
                                <ListItemIcon>
                                    <DirectionsCarFilledIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Vehicles"/>
                            </ListItem>
                        </List>
                        <Divider/>

                        <List>
                            <ListItem button component={NavLink} to="/software" activeClassName="Mui-selected">
                                <ListItemIcon>
                                    <CodeIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Software"/>
                            </ListItem>
                        </List>
                        <Divider/>

                        <List>
                            <ListItem button component={NavLink} to="/links" activeClassName="Mui-selected">
                                <ListItemIcon>
                                    <LinkIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Links"/>
                            </ListItem>
                        </List>
                        <Divider/>


                        {/*<List>*/}
                        {/*    <ListItem button component={NavLink} to="/blog" activeClassName="Mui-selected">*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <RssFeedIcon/>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="Blog"/>*/}
                        {/*    </ListItem>*/}
                        {/*</List>*/}
                        {/*<Divider/>*/}

                        {/*<List>*/}
                        {/*    <ListItem button component={NavLink} to="/about" activeClassName="Mui-selected">*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <InfoIcon/>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="About"/>*/}
                        {/*    </ListItem>*/}
                        {/*</List>*/}
                        {/*<Divider/>*/}
                    </Box>
                </Drawer>
            </Box>
        </Fragment>

    );
};

export default MyDrawer;

