import React from 'react';

import MyDrawer from "./MyDrawer";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
    },
    root: {
        display: 'flex',
    },

}));

const Layout = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>

            < MyDrawer/>

            <div className={classes.content}>
                {props.children}
            </div>

        </div>
    );
};

export default Layout;
