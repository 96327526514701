import './app.css';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import Layout from "./components/layout/Layout";
import MyRoutes from "./routes";
import {AuthContextProvider} from "./store/auth-context";
import {PageContextProvider} from "./store/page-context";
import ReactGA from "react-ga4"

ReactGA.initialize('G-W5BG861RYZ');

function App() {

    return (
        <AuthContextProvider>
            <PageContextProvider>
                {/*<Root>*/}
                <BrowserRouter>
                    <React.Fragment>
                        <Layout>
                            <MyRoutes/>
                        </Layout>
                    </React.Fragment>
                </BrowserRouter>
                {/*</Root>*/}
            </PageContextProvider>
        </AuthContextProvider>
    )
}

export default App;
