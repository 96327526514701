import React, {Fragment, useContext} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import Input from '@mui/material/Input';
import {CardContent, FormControl, FormHelperText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useLocalStorage} from "../../libs/useLocalStorage";


var moment = require('moment');
var humanizeDuration = require('humanize-duration');


// https://www.jcalc.net/battery-size-calculator

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));


export default function CalcCharging(props) {
    const pageCtx = useContext(PageContext);


    const classes = useStyles();

    const theme = useTheme();

    const [cellseries, setCellSeriesValue] = useLocalStorage("cellseries", 8);
    const onCellSeriesChange = (e) => setCellSeriesValue(parseInt(e.target.value));

    const [parallel, setParallelValue] = useLocalStorage("parallel", 1);
    const onParallelChange = (e) => setParallelValue(parseInt(e.target.value));

    const [cellah, setCellAhValue] = useLocalStorage("cellah", 230);
    const onCellAhChange = (e) => setCellAhValue(parseFloat(e.target.value));

    const [cellvolts, setCellVoltValue] = useLocalStorage("cellvolts", 3.2);
    const onCellVoltChange = (e) => setCellVoltValue(parseFloat(e.target.value));

    const [outputwh, setWattHourValue] = useLocalStorage("dischargewh", 40);
    const onOutputWhChange = (e) => setWattHourValue(parseInt(e.target.value));

    const [highestcharge, setHighestChargeValue] = useLocalStorage("highestcharge", 90);
    const onHighestChargeChange = (e) => setHighestChargeValue(Math.max(lowestcharge, parseInt(e.target.value)));

    const [lowestcharge, setLowestChargeValue] = useLocalStorage("lowestcharge", 20);
    const onLowestChargeChange = (e) => setLowestChargeValue(Math.min(highestcharge, parseInt(e.target.value)));

    const [chargeamps, setChargeAmpValue] = useLocalStorage("chargeamps", 30);
    const onChargeAmpsChange = (e) => setChargeAmpValue(parseInt(e.target.value));

    const [chargevolts, setChargeVoltsValue] = useLocalStorage("chargevolts", 110);
    const onChargeVoltsChange = (e) => setChargeVoltsValue(parseInt(e.target.value));


    const [cellvoltsnow, setCellVoltNowValue] = useLocalStorage("topbalancevoltsnow", 3.35);
    const onCellVoltNowChange = (e) => setCellVoltNowValue(parseFloat(e.target.value));

    const [cellvoltsfull, setCellVoltFullValue] = useLocalStorage("topbalancevoltsmax", 3.65);
    const onCellVoltFullChange = (e) => setCellVoltFullValue(parseFloat(e.target.value));

    const [cellinputamps, setInputAmpsValue] = useLocalStorage("topbalanceamps", 10);
    const onInputAmpsNowChange = (e) => setInputAmpsValue(parseFloat(e.target.value));

    const chargefromempty = () => {
        let watthours = cellseries * cellvoltsfull * cellah;
        let hours = watthours / cellinputamps;
        let sec = Math.floor(3600 * hours);
        // let m = moment();
        // m.add(sec, 'seconds');
        // return m.fromNow();
        return humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true});
    }

    const chargefromnow = () => {
        let watthours1 = cellseries * cellvoltsfull * cellah;
        let watthours2 = cellseries * cellvoltsnow * cellah;
        let hours = (watthours1 - watthours2) / cellinputamps;
        let sec = Math.floor(3600 * hours);
        // let m = moment();
        // m.add(sec, 'seconds');
        // return m.fromNow();
        return humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true});
    }

    const ac_input_watts = () => {
        // let n = (cellcount * cellvolts).toFixed(0);
        // if (n < 12) return (cellcount * cellvolts).toFixed(1);
        // if (n < 24) return 12;
        // if (n < 48) return 24;
        // if (n < 55) return 48;
        return (chargeamps * chargevolts).toFixed(0);
    }

    const ac_duration = () => {
        let capacity = cellah * parallel * cellseries * cellvolts;
        let lo = Math.min(100, Math.max(0, lowestcharge));
        let hi = Math.max(0, Math.min(100, highestcharge));
        let charge_rate = chargeamps * chargevolts;

        let low_cut = capacity * (lo / 100);
        let top_cut = capacity * ((100 - hi) / 100);
        capacity = capacity - low_cut - top_cut;

        let hours = capacity / Math.max(1, charge_rate);

        let sec = Math.floor(3600 * hours);
        if (sec > 86400)
            return humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h'], round: true});
        else
            return humanizeDuration(sec * 1000, {units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true});
    }

    const batteryvolts = () => {
        // let n = (cellcount * cellvolts).toFixed(0);
        // if (n < 12) return (cellcount * cellvolts).toFixed(1);
        // if (n < 24) return 12;
        // if (n < 48) return 24;
        // if (n < 55) return 48;
        return (cellseries * cellvolts).toFixed(1);
    }

    const capacity = () => {
        let volts = cellseries * cellvolts;
        return ((cellah * volts * parallel) / 1000).toFixed(2);
    }

    const capacity_usable = () => {
        let capacity = cellah * parallel * cellseries * cellvolts;
        let lo = Math.min(100, Math.max(0, lowestcharge));
        let hi = Math.max(0, Math.min(100, highestcharge));

        let low_cut = capacity * (lo / 100);
        let top_cut = capacity * ((100 - hi) / 100);
        capacity = capacity - low_cut - top_cut;
        return (capacity / 1000).toFixed(2);
    }

    const dischargerange = () => {
        let capacity = cellah * parallel * cellseries * cellvolts;
        let lo = Math.min(100, Math.max(0, lowestcharge));
        let hi = Math.max(0, Math.min(100, highestcharge));

        let low_cut = capacity * (lo / 100);
        let top_cut = capacity * ((100 - hi) / 100);
        capacity = capacity - low_cut - top_cut;

        let hours = capacity / outputwh;
        let msec = Math.floor(3600 * hours * 1000);
        return humanizeDuration(msec, {units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true});
    }

    const dischargeWatts = (hours) => {
        let basewatts = outputwh * Math.max(1, hours);
        if (basewatts % 1000 === 0) return (basewatts / 1000).toFixed(0) + " kWh";
        if (basewatts > 1000) return (basewatts / 1000).toFixed(2) + " kWh";
        return basewatts.toFixed(0) + " Wh";
    }

    const maxdischarge = () => {
        return (cellah * 0.09).toFixed(0);
    }

    const is_a_battery = () => {
        return cellseries > 0 && parallel > 0;
    }

    const is_consumption_ready = () => {
        return outputwh > 0 && lowestcharge >= 0 && highestcharge > lowestcharge;
    }

    const show_top_balancing = () => {
        return false;
    }

    return <React.Fragment>
        <FormControl fullWidth>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline"
            >
                <Grid container xs="3" spacing="1" display="inline-block">

                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h6">
                                AC Charging
                            </Typography>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputamps" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           onChange={onChargeVoltsChange}
                                           defaultValue={chargevolts}
                                    />
                                    <FormHelperText id="my-helper-text">Charge Volts</FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container xs="12" spacing="1">
                                <Grid item>
                                    <Input id="inputamps" aria-describedby="my-helper-text"
                                           type="number"
                                           required
                                           fullWidth
                                           onChange={onChargeAmpsChange}
                                           defaultValue={chargeamps}
                                    />
                                    <FormHelperText id="my-helper-text">Charge Amps</FormHelperText>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>

                    {show_top_balancing() && <Fragment>

                        <Grid container xs="12" spacing="1" height={30}>
                        </Grid>

                        <Card variant="outlined" display="inline-block">
                            <CardContent>
                                <Typography variant="h6">
                                    Top Balancing
                                </Typography>
                                <Grid container xs="12" spacing="1">
                                    <Grid item>
                                        <Input id="inputamps" aria-describedby="my-helper-text"
                                               type="number"
                                               required
                                               fullWidth
                                               onChange={onInputAmpsNowChange}
                                               defaultValue={cellinputamps}
                                        />
                                        <FormHelperText id="my-helper-text">Charge Amp potential</FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container xs="12" spacing="1">
                                    <Grid item>
                                        <Input id="cellvoltfull" aria-describedby="my-helper-text"
                                               defaultValue={cellvoltsfull}
                                               required
                                               fullWidth
                                               onChange={onCellVoltFullChange}
                                               type="number"
                                        />
                                        <FormHelperText id="my-helper-text">Voltage when Full</FormHelperText>
                                    </Grid>
                                </Grid>

                                <Grid container xs="12" spacing="1">
                                    <Grid item>
                                        <Input id="cellvoltnow" aria-describedby="my-helper-text"
                                               defaultValue={cellvoltsnow}
                                               required
                                               fullWidth
                                               onChange={onCellVoltNowChange}
                                               type="number"
                                        />
                                        <FormHelperText id="my-helper-text">Current Average Cell
                                            Voltage</FormHelperText>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Fragment>}
                </Grid>
                <Grid container xs="0.5" spacing="1" display="inline-block">
                </Grid>
                <Grid container xs="6" spacing="1" display="inline-block">
                    <Card variant="outlined" display="inline-block">
                        <CardContent>
                            <Typography variant="h6">
                                Estimates
                            </Typography>

                            {ac_input_watts}

                            <Typography variant="body">
                                <p>
                                    This is a {batteryvolts()}v battery with {capacity()} kWh capacity.
                                    and {capacity_usable()} kWh of usable capacity.
                                </p>
                            </Typography>

                            <Typography variant="body">
                                <p>
                                    Charging is expected to produce {ac_input_watts()} watts of energy
                                    and require <b>{ac_duration()}</b> to charge
                                    from {lowestcharge}% to {highestcharge}%
                                </p>
                            </Typography>


                            {show_top_balancing() && <Fragment>

                                <hr/>


                                <Typography variant="h6">
                                    Top Balancing {cellseries} {cellseries > 1 ? "Cells" : "Cell"} (LifePo4 Only)
                                </Typography>
                                <Typography variant="body">
                                    <p>
                                        Set your variable DC charger to the fixed voltage
                                        of <b>{cellvoltsfull}v</b> and
                                        make sure you have the cells wired in parallel for top balancing. Do
                                        not destroy your cells or cause a fire!
                                    </p>
                                </Typography>
                                <Typography variant="body">
                                    <p>
                                        The amps should reach around {cellinputamps}A while the voltage will drop
                                        below {cellvoltsfull} for most of the time the cells are charging.
                                        As the cells approach full, the voltage will increase and the amperage will
                                        begin to decline. Once it reaches 0.01A or 0.0A the top balancing process
                                        is complete.
                                    </p>
                                </Typography>

                                <Typography variant="body">
                                    <p>
                                        It can be difficult to estimate charging times for Lithium batteries since
                                        the discharge curve is so flat.
                                    </p>
                                    <p>
                                        Charging from empty at a maximum of <b>{cellinputamps}A</b> would
                                        complete in <b>{chargefromempty()}</b>. Given the current voltage
                                        of <b>{cellvoltsnow}v</b>&nbsp;
                                        {cellvoltsnow <= cellvoltsfull ?
                                            <React.Fragment>to complete the top balance will
                                                complete in <b>{chargefromnow()}</b>.
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                your cells are full. Be very careful not to overcharge!
                                            </React.Fragment>
                                        }
                                    </p>
                                    {/*<p>*/}
                                    {/*    <b>THE SECOND MEASURE IS CALCULATED ON SOC and needs to be changed to SOCe</b>*/}
                                    {/*</p>*/}
                                </Typography>
                            </Fragment>}

                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </FormControl>
    </React.Fragment>;
};