import React, {useContext} from "react";
import PageContainer from "../components/ui/PageContainer";
import PageContext from "../store/page-context";
import {Container} from "@mui/material";
import ReactGA from "react-ga4";

export default function About(props) {
    const pageCtx = useContext(PageContext);
    pageCtx.updateTitle("About");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

    return <PageContainer>
        <Container maxWidth>
            All about Tussik will eventually be here, with a story
        </Container>
    </PageContainer>;
};