import React, {Fragment, useContext, useState} from "react";
import {useTheme} from '@mui/material/styles';
import PageContext from "../../store/page-context";
import {makeStyles} from '@mui/styles';
import {CardContent, Container, FormHelperText, Typography} from "@mui/material";
import 'moment-timezone';
import Card from "@mui/material/Card";
import Moment from "react-moment";
import Input from "@mui/material/Input";
import {useLocalStorage} from "../../libs/useLocalStorage";
import ReactGA from "react-ga4";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";


// https://www.jcalc.net/battery-size-calculator

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
        flexGrow: 1
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    formgrid: {
        flexGrow: 1
    }

}));

export default function EpochConverter(props) {
    const pageCtx = useContext(PageContext);
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

    const classes = useStyles();

    const theme = useTheme();


    const [epochnow, setEpochNowValue] = useState(0);

    const [myepoch, setMyEpochValue] = useLocalStorage("myepoch", 0);
    const onMyEpochChange = (e) => setMyEpochValue(parseInt(e.target.value));

    const epoch_timestamp = () => {
        const now = new Date();
        const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
        return Math.round(utcMilllisecondsSinceEpoch / 1000);
    }

    const onResetMyEpoch = (e) => {
        setMyEpochValue(epoch_timestamp());
    }

    const myepoch_local = () => {
        const msoffset = new Date().getTimezoneOffset() * 60 * 1000;
        var dt = new Date((myepoch * 1000) - msoffset);
        return dt;
    }

    const myepoch_utc = () => {
        const msoffset = new Date().getTimezoneOffset() * 60 * 1000;
        var dt = new Date((myepoch * 1000) - msoffset);
        return dt;
    }

    // const display_local = () => {
    //     return moment().format('MMMM Do YYYY, h:mm:ss a')
    // }
    //
    // const display_utc = () => {
    //     return moment().utc().format('MMMM Do YYYY, h:mm:ss a')
    // }

    React.useEffect(() => {
        setEpochNowValue(epoch_timestamp());
        ///moment.tz.guess();
        let intervalid = 0;
        if (intervalid === 0) {
            intervalid = setInterval(() => {
                setEpochNowValue(epoch_timestamp());
            }, 1000);
        }

        return () => clearInterval(intervalid);
    }, [epochnow]);

    return <Fragment>
        <Container maxWidth>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h6">
                        Current Unix epoch time is <b>{epochnow}</b>
                    </Typography>

                    <Typography variant="h6">
                        Local time is <b><Moment format="MMMM Do YYYY, h:mm:ss a Z"/></b>
                    </Typography>

                    <Typography variant="h6">
                        Universal time is <b><Moment format="MMMM Do YYYY, h:mm:ss a Z" utc={true}/></b>
                    </Typography>
                </CardContent>
            </Card>

            <br/>

            <Card variant="outlined">
                <CardContent>
                    <Grid container xs="12" spacing="3">
                        <Grid item xs={2}>
                            <Input id="mydate" aria-describedby="my-helper-text"
                                   value={myepoch}
                                   required
                                   fullWidth
                                   type="number"
                                   onChange={onMyEpochChange}
                            />
                            <FormHelperText id="my-helper-text">Epoch Value</FormHelperText>
                        </Grid>
                        <Grid item>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="outlined"
                                onClick={onResetMyEpoch}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>

                    {myepoch > 0 && <Fragment>
                        <br/>
                        <Typography variant="h6">
                            Local time is <b>
                            <Moment format="MMMM Do YYYY, h:mm:ss a Z" >{myepoch_local()}</Moment></b>
                        </Typography>

                        <Typography variant="h6">
                            Universal time is <b>
                            <Moment format="MMMM Do YYYY, h:mm:ss a Z" utc={true}>{myepoch_utc()}</Moment></b>
                        </Typography>
                    </Fragment>
                    }
                </CardContent>
            </Card>
        </Container>
    </Fragment>;
};