import React, {useState} from 'react';


const PageContext = React.createContext({
    title: '',
    updateTitle: (title) => {
    },
});


export const PageContextProvider = (props) => {
    const [title, setTitle] = useState("");

    typeof window !== "undefined" && localStorage.setItem('title', title);

    const updateTitleHandler = (title) => {
        setTitle(title);

        typeof window !== "undefined" && localStorage.setItem('title', title);

    };

    const contextValue = {
        title: title,
        updateTitle: updateTitleHandler,
    };

    return (
        <PageContext.Provider value={contextValue}>
            {props.children}
        </PageContext.Provider>
    );
};

export default PageContext;
