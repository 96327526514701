import React, {useContext} from "react";
import PageContainer from "../../components/ui/PageContainer";
import PageContext from "../../store/page-context";
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {useNavigate} from "react-router";
import {useTheme} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import VehicleGeneral from "./general";
import VehicleCash from "./cash";
import VehicleLease from "./lease";
import VehicleLoan from "./loan";
import ReactGA from "react-ga4";


/*

Registered account is required for this feature

LISTING SCREEN
--------------
Tab for summary
    -> summary of settings (ex: km vs miles, price of gas, price of electricity)
    -> create new profile
    -> link to download sample report PDF
Tab for profiles
    -> list of profiles
    -> item actions for edit, remove, purchase-report
Tab for settings
    -> imperial vs metric
    -> currency
    -> price of gas
    -> price of electricity
    -> interest rate
    -> inflation rate (over 4 years)

PROFILE SCREEN
--------------
Tab for summary
    -> static information
    -> add new vehicle
Tab for vehicles
    -> list of vehicles
Tab for settings
    -> mileage per year

VEHICLE SCREEN
--------------
form
    -> year, make, model, trim
    -> payment method (lease, loan, cash)
    -> purchase price
    -> oil change cost
    -> maintenance cost each year
    -> depreciation per year starting with year zero

PURCHASE REPORT
---------------
dialog
    -> register first (required) and authenticate
    -> stripe purchase
    -> email address to send PDF report to
    -> post by API the job with transaction-id and profile details
 */


const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 20,
        paddingBottom: 20,
    },
    root: {
        display: 'flex',
    },

    groupheader: {
        backgroundColor: "lightblue"
    },

    itemgrid: {
        paddingLeft: 30,
        paddingRight: 10
    },

    tabpanels: {
        flexGrow: 1
    }

}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const tab_table = [
    {"token": "general", "name": "General", "render": <VehicleGeneral/>},
    {"token": "cash", "name": "Cash", "render": <VehicleCash/>},
    {"token": "lease", "name": "Lease", "render": <VehicleLease/>},
    {"token": "loan", "name": "Loan", "render": <VehicleLoan/>},
];


export default function Vehicles(props) {
    const pageCtx = useContext(PageContext);
    const history = useNavigate();
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});

    // const history = useNavigate();
    pageCtx.updateTitle("Vehicle Calculator");

    let deftab = 0;
    for (let i = 0; i < tab_table.length; i++) {
        if (props.tab === tab_table[i].token) {
            deftab = i;
            break;
        }
    }
    //history({ pathname: `/electric/${tab_table[deftab].token}`});
    //history(`/electric/${tab_table[deftab].token}`);


    const classes = useStyles();

    const theme = useTheme();
    const [value, setValue] = React.useState(deftab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history(`/vehicles/${tab_table[newValue].token}`);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
        // console.log("HISTORY: vehicle " + window.location.pathname + window.location.search);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return <PageContainer>
        <Box sx={{bgcolor: 'background.paper'}}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs"
                >
                    {tab_table.map((tab, idx) =>
                        <Tab label={tab.name} {...a11yProps(idx)}/>
                    )}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {tab_table.map((tab, idx) =>
                    <TabPanel value={value} index={idx} dir={theme.direction}>
                        {tab.render}
                    </TabPanel>
                )}
            </SwipeableViews>
        </Box>
    </PageContainer>;
};