import styled from 'styled-components';


export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
`;

export const Inner = styled.div`
  margin: 0 auto;
  width: 1100px;
  max-width: 100%;
  z-index: 1;
`;

export const Logo = styled.img`
  position: absolute;
  top: 2em;
  left: 2em;
  width: 60px;
`;

export const BottomDivider = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  height: 96px;

  > svg {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

export const AuthenticateButton = styled.a`
  position: absolute;
  top: 0.7em;
  right: 3em;
  display: inline-flex;
  padding: .85em 1.5em;
  font-size: 15px;
  line-height: 1em;
  text-decoration: none;
  border: 2px solid white;
  border-radius: 50em;
  color: white;
  transition: all .3s;

  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
    transform: translate3d(0,-2px,0);
    box-shadow: 0 12px 28px rgb(0 0 0 / 12%);
  }
`;

