// import axios from 'axios';
import {Wrapper} from './login/styles';
import React, {useContext} from 'react';

// import jwt_decode from "jwt-decode";
import AuthContext from "../store/auth-context";
import {useNavigate} from "react-router";
import ReactGA from "react-ga4";


export default function Login() {
    const authCtx = useContext(AuthContext);
    const history = useNavigate();
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});


    // const responseGoogle = (response) => {
    //     const googleToken = response.getAuthResponse().id_token;
    //
    //     const url = 'https://api.tussik.com/auth';
    //
    //     let body = JSON.stringify({"token": googleToken});
    //
    //     if (googleToken != null) {
    //
    //         fetch(url, {
    //             method: "POST",
    //             body: body,
    //             headers: {'Content-Type': 'application/json'}
    //         })
    //             .then(response => response.json())
    //             .then(result => {
    //                 const myToken = result.access;
    //
    //                 const decoded = jwt_decode(myToken);
    //
    //                 const expirationTime = decoded.exp;
    //
    //                 if (myToken != null) {
    //
    //                     axios.get('https://api.tussik.com/auth', {
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             'Authorization': `Bearer ${myToken}`,
    //
    //                         }
    //                     }).then((response) => {
    //
    //                         const userName = response.data.name;
    //
    //                         if (userName != null) {
    //                             authCtx.login(googleToken, expirationTime, myToken, userName);
    //
    //                         } else {
    //
    //                         }
    //
    //                     });
    //                 } else {
    //
    //                 }
    //             })
    //             .catch(error => {
    //                 // ... TODO: manage errors
    //             });
    //
    //
    //     } else {
    //
    //     }
    //
    //     history("/");
    // }


    return (
        <Wrapper>
            <div>TO BE COMPLETED</div>
        </Wrapper>
    );
};
