import styled from 'styled-components';
// import GoogleLogin from 'react-google-login';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import bgImg from './login.jpg';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${bgImg}) center no-repeat;
  background-size: cover;
`;

export const StyledCard = styled(Card)`
  padding: 24px;
  width: 340px;
  text-align: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 auto;
`;

// export const StyledTussikLogin = styled(Button)`
//   background: ${({ theme }) => theme.palette.primary.main} !important;
//   color: #fff !important;
//   margin-top: 20px;
//   &:not(:last-child) {
//     margin-bottom: 20px;
//   }
// `;

