import {getSunrise, getSunset} from 'sunrise-sunset-js';

// https://sustainability.stackexchange.com/questions/4975/converting-from-w-m%C2%B2-to-kwh-m%C2%B2-day
// https://www.researchgate.net/post/How_can_I_convert_Wh_m2_to_W_m2

export default class SolarCalculator {

    // 49.048738
    // -122.871435

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @return {string} url to fetch
     */
    static pvwatt_url(lng, lat) {
        const license = "jlbge6nWAUgZuKxOvK8uqq4adFh0kYgHPthi69me";
        let url = "https://developer.nrel.gov/api/pvwatts/v6.json";
        url += "?api_key=" + license + "&lat=" + lat + "&lon=" + lng;
        url += "&system_capacity=4&azimuth=180&tilt=40&array_type=1&module_type=1&losses=10";
        return url;
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {int} month Month from 0-11
     * @return {number} average hours of pv
     */
    static month_pv(lng, lat, month) {

        // TODO: loop every day of the month
        // TODO: calculate hours in the day
        // TODO: average sun hours in the day
        let sunhours = this.sun_hours_bymonth(lng, lat);
        return sunhours[month];
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {Date} date current date
     * @return {int} seconds of sunlight
     */
    static sun_hours(lng, lat, date) {
        let sr = getSunrise(lat, lng, date);
        let ss = getSunset(lat, lng, date);
        let sec_sr = (sr.getHours() * 3600) + (sr.getMinutes() * 60) + sr.getSeconds();
        let sec_ss = (ss.getHours() * 3600) + (ss.getMinutes() * 60) + ss.getSeconds();
        // var ts_sr = sr.getDay() + "d " + sr.getHours() + ":" + sr.getMinutes() + ":" + sr.getSeconds();
        // var ts_ss = ss.getDay() + "d " + ss.getHours() + ":" + ss.getMinutes() + ":" + ss.getSeconds();
        // console.log("sunrises at " + ts_sr + " and sunsets at " + ts_ss);
        // let sec = (ss.getTime() - sr.getTime()) / 1000;
        return Math.abs(sec_ss - sec_sr);
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {int} month month value 0 to 11
     * @return {number} seconds of sunlight
     */
    static sun_hours_month(lng, lat, month) {
        const result = [];
        const year = new Date().getFullYear();
        let d = new Date(year, month, 1);
        for (let i = 0; i < 32; i++) {
            d = new Date(d.setTime(d.getTime() + (24 * 60 * 60 * 1000)));
            if (month !== d.getMonth()) break;
            const sun = this.sun_hours(lng, lat, d);
            result.push(sun);
        }
        const total = result.reduce((a, b) => a + b, 0);
        return ((total / result.length) || 0).toFixed(2);
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @return {array} seconds of sunlight
     */
    static sun_hours_year(lng, lat) {
        const result = [];
        try {
            for (let i = 0; i < 12; i++) {
                const sun = this.sun_hours_month(lng, lat, i);
                result.push(sun);
            }
        } catch (e) {
            console.log("blew up sun_hours_year");
        }
        return result;
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {Date} date current date
     * @return {number} altitude
     */
    static get_altitude(lng, lat, date) {
        let sun_declination = 0;
        let hour_angle = 0;
        const temp_kelvin = 288.15;
        const pressure_pascals = 101325.00;
        /*
        topocentric_sun_declination, topocentric_local_hour_angle = \
        get_topocentric_position(latitude_deg, longitude_deg, when)
         */

        const elev_angle = this.get_topocentric_elevation_angle(lat, sun_declination, hour_angle);
        const refraction_correction = this.get_refraction_correction(temp_kelvin, pressure_pascals, elev_angle);
        return elev_angle + refraction_correction;
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {Date} date current date
     * @return {Object} declination and angle
     */
    static get_topocentric_position(lng, lat, date) {
        let sun_declination = 0;
        let hour_angle = 0;
        let elevation = 0;

        const projected_radial_distance = this.get_projected_radial_distance(elevation, lat)
        const projected_axial_distance = this.get_projected_axial_distance(elevation, lat)

        return {"sun_declination": sun_declination, "hour_angle": hour_angle};
    }

    /*
        # time-dependent calculations
        jd = stime.get_julian_solar_day(when)
        jde = stime.get_julian_ephemeris_day(when)
        jce = stime.get_julian_ephemeris_century(jde)
        jme = stime.get_julian_ephemeris_millennium(jce)
        geocentric_latitude = get_geocentric_latitude(jme)
        geocentric_longitude = get_geocentric_longitude(jme)
        sun_earth_distance = get_sun_earth_distance(jme)
        aberration_correction = get_aberration_correction(sun_earth_distance)
        equatorial_horizontal_parallax = get_equatorial_horizontal_parallax(sun_earth_distance)
        nutation = get_nutation(jce)
        apparent_sidereal_time = get_apparent_sidereal_time(jd, jme, nutation)
        true_ecliptic_obliquity = get_true_ecliptic_obliquity(jme, nutation)

        # calculations dependent on location and time
        apparent_sun_longitude = get_apparent_sun_longitude(geocentric_longitude, nutation, aberration_correction)
        geocentric_sun_right_ascension = get_geocentric_sun_right_ascension(apparent_sun_longitude, true_ecliptic_obliquity, geocentric_latitude)
        geocentric_sun_declination = get_geocentric_sun_declination(apparent_sun_longitude, true_ecliptic_obliquity, geocentric_latitude)
        local_hour_angle = get_local_hour_angle(apparent_sidereal_time, longitude_deg, geocentric_sun_right_ascension)
        parallax_sun_right_ascension = get_parallax_sun_right_ascension(projected_radial_distance, equatorial_horizontal_parallax, local_hour_angle, geocentric_sun_declination)
        topocentric_local_hour_angle = get_topocentric_local_hour_angle(local_hour_angle, parallax_sun_right_ascension)
        topocentric_sun_declination = get_topocentric_sun_declination(geocentric_sun_declination, projected_axial_distance, equatorial_horizontal_parallax, parallax_sun_right_ascension, local_hour_angle)

        return topocentric_sun_declination, topocentric_local_hour_angle
     */

    /**
     * @param {number} elevation Elevation
     * @param {number} lat Latitude
     * @return {number} calculation
     */
    static get_projected_radial_distance(elevation, lat) {
        const flat_lat_rad = this.Radians(this.get_flattened_latitude(lat));
        const lat_rad = this.Radians(lat);
        const earth_radius_meters = 6378140.0;
        return Math.cos(flat_lat_rad) + (elevation * Math.sin(lat_rad) / earth_radius_meters);
    }

    /**
     * @param {number} lat Latitude
     * @return {number} calculation
     */
    static get_flattened_latitude(lat) {
        const lat_rad = this.Radians(lat);
        return this.Degrees(Math.atan(0.99664719 * Math.tan(lat_rad)));
    }

    /**
     * @param {number} elevation Elevation
     * @param {number} lat Latitude
     * @return {number} calculation
     */
    static get_projected_axial_distance(elevation, lat) {
        const flat_lat_rad = this.Radians(this.get_flattened_latitude(lat));
        const lat_rad = this.Radians(lat);
        const earth_radius_meters = 6378140.0;
        return 0.99664719 * Math.sin(flat_lat_rad) + (elevation * Math.sin(lat_rad) / earth_radius_meters);
    }


    /**
     * @param {number} radians Radians
     * @return {number} degrees
     */
    static Degrees(radians) {
        return radians * (180 / Math.PI);
    }

    /**
     * @param {number} degrees Degrees
     * @return {number} Radians
     */
    static Radians(degrees) {
        return degrees * (Math.PI / 180);
    }

    /**
     * @param {number} lat latitude
     * @param {number} sun_declination top centric sun declination
     * @param {number} hour_angle top centric local hour angle
     * @return {number} degrees
     */
    static get_topocentric_elevation_angle(lat, sun_declination, hour_angle) {
        const lat_rad = this.Radians(lat);
        const tsd_rad = this.Radians(sun_declination);
        const tlha_rad = this.Radians(hour_angle);
        let v = (Math.sin(lat_rad) * Math.sin(tsd_rad)) + Math.cos(lat_rad) * Math.cos(tsd_rad) + Math.cos(tlha_rad);
        return this.Degrees(Math.asin(v))

    }

    /*
    def get_topocentric_elevation_angle(latitude, topocentric_sun_declination, topocentric_local_hour_angle):
        latitude_rad = math.radians(latitude)
        tsd_rad = math.radians(topocentric_sun_declination)
        tlha_rad = math.radians(topocentric_local_hour_angle)
        return math.degrees(math.asin((math.sin(latitude_rad) * math.sin(tsd_rad)) + math.cos(latitude_rad) * math.cos(tsd_rad) * math.cos(tlha_rad)))
     */

    /**
     * @param {Date} date current date
     * @param {number} degree altitude degree
     * @return {number} radiation
     */
    static get_radiation_direct(date, degree) {
        return 0.0;
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {Date} date current date
     * @return {number} radiation average
     */
    static get_radiation_day(lng, lat, date) {
        const results = [];
        let d = new Date(date.getFullYear(), date.getMonth(), date.getDay(), 1, 0, 0, 0);
        let radiation = 0.0;
        for (let i = 0; i < 24; i++) {
            d = d.setTime(d.getTime() + (60 * 60 * 1000));
            let degrees = this.get_altitude(lng, lat, date);
            if (degrees <= 0) {
                radiation = 0.0;
            } else {
                radiation = this.get_radiation_direct(d, degrees);
            }
            results.push(radiation);
        }
        const total = results.reduce((a, b) => a + b, 0);
        return Math.floor((total / results.length) || 0);
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @param {int} month Month 0 to 11
     * @return {number} radiation average
     */
    static get_radiation_month(lng, lat, month) {
        const results = [];
        const now = new Date();
        let d = new Date(now.getFullYear(), month, 1);
        for (let i = 1; i < 31; i++) {
            d = new Date(d.setTime(d.getTime() + (24 * 60 * 60 * 1000)));
            if (month !== d.getMonth()) break;
            let n = this.get_radiation_day(lng, lat, d);
            results.push(n);
        }
        const total = results.reduce((a, b) => a + b, 0);
        return Math.floor((total / results.length) || 0);
    }

    /**
     * @param {number} lng Longitude
     * @param {number} lat Latitude
     * @return {array} list of radiation by month
     */
    static get_radiation_bymonth(lng, lat) {
        const results = [];
        for (let i = 0; i < 11; i++) {
            const value = this.get_radiation_month(lng, lat, i);
            results.push(value);
        }
        return results;
    }

}
